import React from 'react'
import PropTypes from 'prop-types'
import SonyLogo from '../assets/icons/sony.svg'
import LexusLogo from '../assets/icons/lexus.svg'
import IngLogo from '../assets/icons/ing.svg'
import AtlassianLogo from '../assets/icons/atlassian.svg'
import VirginLogo from '../assets/icons/virgin.svg'
import IkeaLogo from '../assets/icons/ikea.svg'
import VeritivLogo from '../assets/icons/veritiv.svg'
import LgLogo from '../assets/icons/lg.svg'
import SamsungLogo from '../assets/icons/samsung.svg'
import RyanairLogo from '../assets/icons/ryanair.svg'

const Brands = () => {
  return (
    <section className="o-container">
      <div className="u-flex u-justify-between brand-wrapper">
        <div className="brand-item">
          <SonyLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <IngLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <AtlassianLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <LexusLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <VirginLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <IkeaLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <LgLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <RyanairLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <VeritivLogo className="brand-logo" />
        </div>
        <div className="brand-item">
          <SamsungLogo className="brand-logo" />
        </div>
      </div>
    </section>
  )
}

Brands.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  readNext: PropTypes.bool,
  links: PropTypes.array,
  rightLink: PropTypes.object,
  button: PropTypes.string,
  buttonLink: PropTypes.string,
}

export default Brands
