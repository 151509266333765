import React, { Fragment } from 'react'
import Layout from '../../layout'
import {
  SEO,
  Tour,
  Plan,
  Features,
  Feature,
  More,
  Hero,
  GetStarted,
  Carousel,
} from '../../components'

import MoneyBagIcon from '../../assets/icons/revenue.svg'
import LightbulbIcon from '../../assets/icons/lightbulb.svg'
import CustomerIcon from '../../assets/icons/customer.svg'
import ArrowIcon from '../../assets/icons/pointing-arrow.svg'
import './developer-program.less'

import DeveloperProgramOgImage from '../../assets/images/developer-program-seo-image.png'
import Brands from '../../components/Brands'

const Homepage = () => {
  const apps = [
    {
      title: 'WhatsApp Business',
      alt: 'WhatsApp Business app logo',
      description: 'A robust communication channel to power up your business.',
      icon: 'whatsappBusiness',
      link: 'https://www.livechat.com/marketplace/apps/whatsapp-business/',
    },
    {
      title: 'ChatBot',
      alt: 'ChatBot app logo',
      description:
        'Integrate LiveChat with ChatBot to get a customer service chatbot!',
      icon: 'chatbot',
      link: 'https://www.livechat.com/marketplace/apps/chatbot/',
    },
    {
      title: 'SnapCall',
      alt: 'SnapCall app logo',
      description:
        'Drive sales by elevating chats to voice, video, and screen sharing.',
      icon: 'snapcall',
      link: 'https://www.livechat.com/marketplace/apps/snapcall/',
    },
    {
      title: 'Mailchimp',
      alt: 'Mailchimp app logo',
      description:
        'Convert leads into customers by sending personalized marketing after the chat',
      icon: 'mailchimp',
      link: 'https://www.livechat.com/marketplace/apps/mailchimp/',
    },
    {
      title: 'Slack for LiveChat',
      alt: 'Slack for LiveChat app logo',
      description:
        'Enjoy the power of your favorite software working seamlessly together.',
      icon: 'slackForLivechat',
      link: 'https://www.livechat.com/marketplace/apps/slack-for-livechat/',
    },
  ]

  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Developer Program"
        description="Build communication tools with us. Join LiveChat Developer Program to grow your business and build new revenue streams."
        image={DeveloperProgramOgImage}
      />
      <div className="u-bg-black-2 u-Pt-xl u-Pb-2xl">
        <GetStarted
          title={'Developer Program'}
          subtitle={
            <React.Fragment>
              For developers <br /> by developers
            </React.Fragment>
          }
          description={
            <React.Fragment>
              Join Developer Program to build apps and sell them on the LiveChat
              Marketplace. <br />
              Build next-level comunication tools!
            </React.Fragment>
          }
        />
      </div>
      <div className="u-bg-black-3 u-Pt-xl">
        <More
          readNext={false}
          button={'See Developer Program offer'}
          buttonLink="/developer-program/offer/"
          label={'Why build with us?'}
          links={[
            {
              title: 'Plug-and-play app monetization',
              description:
                'Configure app monetization in just a few clicks and create a solid stream of passive income in no time.',
              link: `${process.env.GATSBY_DOCS_URL}monetization`,
              icon: MoneyBagIcon,
              linkOutside: true,
            },
            {
              title: 'Many apps yet to be built',
              description:
                'Our Marketplace is still growing. There are dozens of ideas waiting to be turned into functioning apps.',
              link: '/omnichannel/',
              icon: LightbulbIcon,
              linkOutside: false,
            },
            {
              title: 'Customer base from day one',
              description:
                'LiveChat is used by 34,000+ companies around the world. Publish you app and reach every one of them.',
              link: `${process.env.GATSBY_PRODUCT_WEBSITE_URL}customers`,
              icon: CustomerIcon,
              linkOutside: true,
              to: true,
            },
          ]}
        />
        <div className="u-bg-black-3 u-Pt-2xl u-Pb-xl">
          <Plan
            title={'What you build depends on... you!'}
            gray
            subtitle={
              <Fragment>
                You can build simple web apps as well as complex <br />
                solutions integrating LiveChat with other services.
              </Fragment>
            }
          />

          <Tour
            inverse
            gray
            noMargin
            features={[
              {
                image: 'openAgentAppTour1',
                alt: 'LiveChat interfaces',
                title: 'Extend LiveChat interfaces',
                description:
                  "Load helpful apps right into the LiveChat Agent Application. Update info on the fly and provide interactive elements for agents' convenience.",
              },
              {
                image: 'openAgentAppTour2',
                alt: 'LiveChat server-side integrations',
                title: 'Develop server-side integrations',
                description:
                  'Connect LiveChat with third-party services making use of webhooks and real-time messaging APIs. Create integrations to amplify the LiveChat experience.',
              },
              {
                image: 'openAgentAppTour3',
                alt: 'LiveChat APIs',
                title: 'Build private solutions for your team',
                description:
                  "Use our APIs to create tailored solutions available exclusively for your organization and enhance your team's daily work.",
              },
            ]}
          />
        </div>
      </div>

      <section className="u-bg-black2 u-Pt-xl u-Pb-md">
        <div className="u-bg-black2">
          <Feature
            inverse
            smallMarginBottom
            smallImg
            imgAlt="Developer Program Toolkits"
            imgScale
            imgObjectFit="contain"
            image="toolkit"
            title={
              <Fragment>
                From research to promotion.
                <br />
                Support at every stage.
              </Fragment>
            }
            description="We're product people and we know what it takes to launch a successful app. We prepared two toolkits to help your app become a bestseller on the Marketplace. "
          />
          <Features
            gray
            noPadding
            verticalMargin
            features={[
              {
                title: 'Research Toolkit',
                description:
                  'We provide you with data, insights and reports on what LiveChat customers currently need and wish for.',
                link: '/developer-program/offer/#research-toolkit',
              },
              {
                title: 'Development Toolkit',
                description:
                  'We deliver documentation, APIs, SDKs, and design systems so you can build almost anything into LiveChat.',
                link: '/developer-program/offer#development-toolkit',
              },
              {
                title: 'Distribution Toolkit',
                description:
                  'We can help you boost your sales with the use of our marketing and distribution tools.',
                link: '/developer-program/offer#distribution-toolkit',
              },
            ]}
          />
        </div>
      </section>

      <section className="u-bg-black-3">
        <div className="u-Pt-xl u-Pb-xl">
          <Plan
            marginBottom="0"
            color="white"
            title={
              <Fragment>
                Discover <span className="u-text-mark u-text-nowrap">apps</span>{' '}
                from other developers
              </Fragment>
            }
            subtitle={
              <Fragment>
                Our Marketplace is full of successful apps <br />
                that LiveChat customers love.
              </Fragment>
            }
          >
            <Carousel apps={apps} />
          </Plan>
          <div className="u-text-center">
            <a
              href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}marketplace`}
              className="c-link u-text-livechat u-text-p6-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              Explore apps on Marketplace
            </a>
          </div>
        </div>
        <div className="u-Pt-xl u-Pb-2xl">
          <Plan
            color="gray"
            biggerTitle
            marginBottom="u-Mb-md"
            title={
              <Fragment>
                Our existing customers are the <br /> potential users of{' '}
                <span className="u-text-mark u-text-nowrap">your app</span>
              </Fragment>
            }
            subtitle={
              <Fragment>
                Create solutions that will meet the needs of our <br />
                customers so they become your customers, too.
              </Fragment>
            }
          />
          <Brands />
        </div>

        <div className="u-Py-md u-Pb-xl">
          <Hero
            name="Arnaud Pigueller"
            position="SnapCall Co-Founder and CEO"
            quotation={
              <Fragment>
                With the LiveChat Developer Program we were able to create an
                amazing video experience right in the chat.
              </Fragment>
            }
            image="arnaudPigueller"
            alt="Arnaud Pigueller, SnapCall"
            button={{
              link: process.env.GATSBY_CONSOLE_URL,
              label: 'Join Developer Program',
            }}
            link={{
              link: process.env.GATSBY_DOCS_URL,
              label: 'browse the docs',
            }}
          />
        </div>
      </section>

      <section className="u-bg-black-7">
        <div className="u-Py-2xl u-text-center u-relative">
          <Plan
            marginBottom="0"
            color="gray"
            biggerTitle
            title="Start building your apps"
            subtitle={'Register for free to develop integrations'}
          />
          <div className="u-Mt-md" />
          <ArrowIcon className="arrow" />
          <a
            href={`${process.env.GATSBY_CONSOLE_URL}`}
            className="u-w-fit u-mt-xs c-btn v--livechat u-text-white u-text-p7-bold"
          >
            Join Developer Program
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default Homepage
